import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"1000"}},[_c(VCardTitle,{staticClass:"blue-grey white--text"},[_c('span',{staticClass:"text-h6"},[_vm._v("Notifiche")])]),_c(VCardText,{staticClass:"py-0"},[_c(VTimeline,{attrs:{"dense":""}},[_c(VSlideXReverseTransition,{attrs:{"group":"","hide-on-leave":""}},_vm._l((_vm.userNotifications),function(notification){return _c(VTimelineItem,{key:notification.id,attrs:{"color":_vm.dotColor(notification.is_read),"small":""}},[_c(VLazy,{attrs:{"options":{
                                threshold: .5
                            }}},[_c(VAlert,{staticClass:"white--text",attrs:{"value":true,"color":_vm.alertColor(notification.is_read),"icon":_vm.alertIcon(notification.is_read),"border":"left","prominent":"","dense":"","text":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow"},[_c('span',[_vm._v(_vm._s(notification.notification_text))]),_c('br'),_vm._v(" "+_vm._s(notification.procedure.title)+" - "),(notification.user.role==='professional')?_c('span',[_vm._v(_vm._s(notification.user.company.name))]):_vm._e(),(notification.user.role==='user')?_c('span',[_vm._v(_vm._s(notification.user.name)+" "+_vm._s(notification.user.surname))]):_vm._e(),_c('br'),_vm._v(" "+_vm._s(notification.created_at_text)+" ")]),_c(VCol,{staticClass:"shrink"},[_c(VBtn,{attrs:{"color":_vm.dotColor(notification.is_read),"to":notification.procedure.linkTo}},[_vm._v(" LEGGI ")])],1)],1)],1)],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }