<template>
    <div>
        <v-card
            class="mx-auto"
            max-width="1000"
        >
            <v-card-title
                class="blue-grey white--text"
            >
                <span class="text-h6">Notifiche</span>
            </v-card-title>
            <v-card-text class="py-0">
                <v-timeline dense>
                    <v-slide-x-reverse-transition
                        group
                        hide-on-leave
                    >
                        <v-timeline-item
                            v-for="notification in userNotifications"
                            :key="notification.id"
                            :color="dotColor(notification.is_read)"
                            small
                        >
                            <v-lazy
                                :options="{
                                    threshold: .5
                                }"
                            >
                                <v-alert
                                    :value="true"
                                    :color="alertColor(notification.is_read)"
                                    :icon="alertIcon(notification.is_read)"
                                    class="white--text"
                                    border="left"
                                    prominent
                                    dense
                                    text
                                >
                                    <v-row align="center">
                                        <v-col class="grow">
                                            <span>{{ notification.notification_text }}</span><br>
                                            {{ notification.procedure.title }} -
                                            <span v-if="notification.user.role==='professional'">{{ notification.user.company.name }}</span>
                                            <span v-if="notification.user.role==='user'">{{ notification.user.name }} {{ notification.user.surname }}</span>
                                            <br>
                                            {{ notification.created_at_text }}
                                        </v-col>
                                        <v-col class="shrink">
                                            <v-btn
                                                :color="dotColor(notification.is_read)"
                                                :to="notification.procedure.linkTo"
                                            >
                                                LEGGI
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-alert>
                            </v-lazy>
                        </v-timeline-item>
                    </v-slide-x-reverse-transition>
                </v-timeline>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    components: {},
    data() {
        return {
            breadCrumb: [
                {
                    text: 'Dashboard',
                    to: '/dashboard',
                },
                {
                    text: 'Notifiche',
                    to: '/notifications',
                },
            ],
        }
    },
    mounted() {
        this.$store.dispatch('setBreadCrumbLevels',this.breadCrumb)
    },
    methods: {
        dotColor: function(is_read) {
            if(is_read === 'no'){
                return 'primary'
            }

            return 'blue-grey lighten-4'
        },
        alertColor: function(is_read) {
            if(is_read === 'no'){
                return 'primary'
            }

            return 'blue-grey lighten-4'
        },
        alertIcon: function(is_read) {
            if(is_read === 'no'){
                return 'mdi-alert-circle'
            }

            return 'mdi-check-circle'
        }
    },
    computed: {
        ...mapGetters(['loggedUser','userNotifications']),
    },
}
</script>